import React, { memo, useState } from "react"

import InfoModal from "./InfoModal"
import { Button } from "antd"
import DevelopmentProcess from "./WEB_DevelopmentProcess"
import ServiceStepsProcess from "./ServiceStepsProcess"
const SimplifiedStepstoEngageDevelopersWithUs = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    return (
        <div className="services-page-container">
            <DevelopmentProcess title='Simplified Steps to Engage Developers' >
            <ServiceStepsProcess process_heading='Let us know the Resource Requirement' process_para="Share your resource requirement with us, we shall swiftly reach out to you to discuss how we can help." process_step_number="1" process_indentify="odd" >
            <Button className="button-our" onClick={showModal}>
              Let’s Discuss
            </Button>
            </ServiceStepsProcess>
            <ServiceStepsProcess process_heading='Connect with out Account Manager' process_para="Our consultant will dive into your resources requirement and align the best suited resource for you." process_step_number="2"  />
            <ServiceStepsProcess process_heading='Meet our Developer ' process_para="Engage with proposed developer, be satisfied with the expertise of the developer." process_step_number="3" process_indentify="odd" iconShow="dark"/>
            <ServiceStepsProcess process_heading='Agreement Finalization' process_para="Finalize terms of engagement and agree upon the process of transparency and escalation." process_step_number="4" iconShow="dark" />
            <ServiceStepsProcess process_heading='Continual Collaboration' process_para="Expect ongoing support and active communication from our account manager." process_step_number="5" process_indentify="odd" />
            <ServiceStepsProcess process_heading='Progressive Elaboration' process_para="Continual review and improvement with respect to expected quality deliverable." process_step_number="6" />
            </DevelopmentProcess>
            <InfoModal
                companyIsEnabled
                url="http://185.213.27.237:8066/Email/ContactUs"
                isModalVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
        </div>
    )
}

export default memo(SimplifiedStepstoEngageDevelopersWithUs)
