import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../../components/Layout"
import StaffaugmentationHeader from '../../components/StaffaugmentationHeader'
import "../../styles/services.scss"
import Whychooseourdevelopers from '../../components/Whychooseourdevelopers'
import OurAugmentationOfferings from '../../components/OurAugmentationOfferings'
import News from '../../components/News'
import SimplifiedStepstoEngageDevelopersWithUs from '../../components/SimplifiedStepstoEngageDevelopersWithUs'
const Index = ({ data }) => {
  return (
    <div className='Staffaugmentation'>
      <Layout pageTitle={"Visiomate - Staff Augmentation Services"}
      descrip = {"Scale your team seamlessly with staff augmentation services. Access top tech talent on-demand, reduce costs, and meet project deadlines. Contact us today!"}>
        <StaffaugmentationHeader data={data} />
        <Whychooseourdevelopers data={data} />
        <OurAugmentationOfferings />
        <SimplifiedStepstoEngageDevelopersWithUs />
        <News/>
      </Layout>
    </div>
  )
}

export default Index
export const webDevelopmentQuery = graphql`
  query StaffaugmentationQuery {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage: file(relativePath: { eq: "Mask_group8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    EMS: file(relativePath: { eq: "EMS/ems_side.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Viztos: file(relativePath: { eq: "ViztosPos/viztos_side.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Tender: file(relativePath: { eq: "TenderAssetRecordManagement/trn_side.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    div1Img: file(relativePath: { eq: "46807_idea_group_icon_1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      div2Img: file(relativePath: { eq: "7638097_online_library_digital.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      div3Img: file(relativePath: { eq: "669939_adaptive_grid.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      div4Img: file(relativePath: { eq: "669947_hourglass_clock_loading_time_watch_icon_1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      div5Img: file(relativePath: { eq: "4698591_chat_communication_conversation_talk_icon (1)_1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      div6Img: file(relativePath: { eq: "669953_purse_dollars_finance_money_pay_icon (2).png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
  }
`