import React, { memo } from "react"
import { useState } from "react"
import Img from "gatsby-image"
import { Typography } from "antd"
import { Link } from "gatsby"
import InfoModal from "../components/InfoModal"
const { Title } = Typography

const StaffaugmentationHeader = ({ data }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }
    const backgroundImage = data.bgImage.childImageSharp.fluid.src
    return (
        <div
            className="top-section"
            style={{ color: "red", backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="logo">
                <Link to="/">
                    <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
                </Link>
            </div>

            <div className="headings-container">
                <Title>
                    Elevate Your Projects with Customized Staff Augmentation Solutions.
                    Access our skilled professionals to bolster your team, meet project
                    demands, and drive success efficiently.
                </Title>
            </div>
            <button onClick={showModal}>Tell us about your project</button>
            <InfoModal
                companyIsEnabled
                url="http://46.101.195.121:8066/email/ContactUs"
                isModalVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
        </div>
    )
}

export default memo(StaffaugmentationHeader)
