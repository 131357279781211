import React from 'react'
import Arrow from '../images/Arrow 1.jpg'


function ServiceStepsProcess({process_heading,process_para,process_step_number,process_indentify,iconShow,children}) {
  return (
    <div className={`${process_indentify=="odd"?"odd_process":"even_process"} process_step`}>
        <div className="process_description">
          <h4 className="process_description_heading">{process_heading}</h4>
          <p className="process_description_para">{process_para}</p>
          {children}
        </div>
        <img src={Arrow} alt="Arrow" className="process-arrow"/>
        <div className="process_indication">
        {iconShow!="dark"?<svg width="104" height="154" viewBox="0 0 104 154" fill="none" className="icon-indication" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.48699 62.2062C-1.32798 70.1498 -1.32797 83.9772 7.487 91.9208L70.1112 148.355C82.9788 159.951 103.5 150.819 103.5 133.498L103.5 20.6294C103.5 3.30791 82.9788 -5.82366 70.1112 5.772L7.48699 62.2062Z" fill="#0239B2"/>
        </svg>:
        <svg width="104" height="154" viewBox="0 0 104 154" fill="none" className="icon-indication" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.48699 62.1268C-1.32798 70.0705 -1.32797 83.8978 7.487 91.8415L70.1112 148.276C82.9788 159.871 103.5 150.74 103.5 133.418L103.5 20.55C103.5 3.22857 82.9788 -5.903 70.1112 5.69266L7.48699 62.1268Z" fill="#111B71"/>
        </svg>}
        <p className="process_indication_number">{process_step_number}</p>
        </div>
      </div>
  )
}

export default ServiceStepsProcess